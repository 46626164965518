
import { RadioProgramJsonItem } from "../../../jsonSerializer/radioScheduleJsonSerializer";
import { AudioPlayButton, TogglePlayAction, AudioRendererProps } from "../floatingPlayer/audioPlayButton";
import { LiveFloatingRadioPlayer } from "../floatingPlayer/LiveFloatingRadioPlayer";
import { LiveProgressBar } from "./liveProgressBar";
import { PlaybackProgressBar } from "./playbackProgressBar";
import { isWcmEditor } from "config/serverConfig"
import { AdsProgressBar } from "./adsProgressBar"
import { buildAdTag } from "../floatingPlayer/imaAdTagBuilder";
import { getDevice } from "../../../siteWidgets/videoPlayer/videoPlayerAds"
import { SimpleLiveFloatingPlayer } from "../floatingPlayer/simpleLiveFloatingPlayer";

const HLS_LOADED_EVENT = "hls_lodaed";
declare var Hls: any

export interface RadioProgramItem extends Partial<RadioProgramJsonItem> {
    startTime: string
    endTime: string
}

interface PlayerComponentaProps extends AudioRendererProps {
    programItem: RadioProgramItem
}

export interface DvrRadioPlayerProps {
    title: string;
    liveBroadCastDVRSource: string
    liveBroadCastSource: string
    radioScheduleList: RadioProgramItem[]
    startingIndex: number
    isMobileWeb: boolean
    playerComponentaRenderer: (props: PlayerComponentaProps) => React.ReactElement<PlayerComponentaProps>
    onCurrentShowIndexChange?: (currentShowIndex: number) => void
    shouldAppearOnComponentOutOfView?: boolean
    site?: string
    isSimplePlayer?: boolean
}

interface DvrRadioPlayerState {
    showPlayingIndex: number
    playbackProgramIndex: number
    startPostion: number
    isHlsLoaded: boolean
    isHlsLoadingError: boolean
    isAdPlaying: boolean
}

declare var window: Window & {
    wcmAudioPlayer: AudioPlayButton
}

export class DvrRadioPlayer extends React.Component<DvrRadioPlayerProps, DvrRadioPlayerState> {
    public static siteScriptName = "RadioHomepagePlayerComponenta"

    private timer;
    private playButtonRef: AudioPlayButton;

    constructor(props) {
        super(props)
        const isIos = getDevice() === "iphone"
        if (!isWcmEditor() && !isIos && typeof window !== 'undefined' && !document.getElementById("hlsjsscript")) {
            const script = document.createElement('script')
            script.id = "hlsjsscript"
            script.src = 'https://cdn.jsdelivr.net/npm/hls.js@1.2.3'
            script.onload = () => document.dispatchEvent(new CustomEvent(HLS_LOADED_EVENT));
            script.onerror = this.updateHlsLoadError
            document.head.appendChild(script)
        }

        this.state = {
            showPlayingIndex: props.startingIndex,
            playbackProgramIndex: -1, // -1: live,   any other index: playback
            startPostion: -1,
            isHlsLoaded: isWcmEditor() || typeof Hls !== "undefined" || isIos || typeof window === 'undefined',
            isHlsLoadingError: false,
            isAdPlaying: false,
        }
    }

    componentDidMount() {
        document.addEventListener(HLS_LOADED_EVENT, this.updateHlsLoaded)
        this.createTimerForNextShow()
    }

    componentWillUnmount() {
        document.removeEventListener(HLS_LOADED_EVENT, this.updateHlsLoaded)
        clearTimeout(this.timer);
    }

    private updateHlsLoaded = () => {
        this.setState({ isHlsLoaded: true })
    }

    private updateHlsLoadError = () => {
        this.setState({ isHlsLoadingError: true })
    }

    private onAdPlayingStateChange = (state) => {
        this.setState({ isAdPlaying: state })
    }

    createTimerForNextShow = () => {
        const { radioScheduleList, onCurrentShowIndexChange } = this.props
        const { showPlayingIndex } = this.state

        if (radioScheduleList.length > 0 && showPlayingIndex !== -1 && showPlayingIndex + 1 < radioScheduleList.length && radioScheduleList[showPlayingIndex] && radioScheduleList[showPlayingIndex].endProgram) {
            this.timer = setTimeout(() => {
                this.setState({ showPlayingIndex: showPlayingIndex + 1 }, () => {
                    //recursive until no show is available
                    const program = radioScheduleList[this.state.showPlayingIndex]
                    onCurrentShowIndexChange && onCurrentShowIndexChange(this.state.showPlayingIndex)
                    this.playButtonRef && document.dispatchEvent(
                        new CustomEvent("radioFlashChange",
                            {
                                detail: {
                                    program,
                                    isPlaying: this.playButtonRef.isPlaying(),
                                }
                            }
                        ))
                    this.createTimerForNextShow()
                })
            }, new Date(radioScheduleList[showPlayingIndex].endProgram).getTime() - new Date().getTime());
        }
    }

    private setPlayButtonRef = (ref: AudioPlayButton) => {
        this.playButtonRef = ref
    }

    private toLive = () => {
        this.setState({ playbackProgramIndex: -1, startPostion: undefined }, () => { window.wcmAudioPlayer.play() })
        document.dispatchEvent(new CustomEvent("dvrRadioPlayerToLive"))
    }

    private toPlayback = (startPostion: number, disablePlay?: boolean) => {
        this.setState({ playbackProgramIndex: this.state.showPlayingIndex, startPostion }, () => { !disablePlay && window.wcmAudioPlayer.play(true) })
        !disablePlay && document.dispatchEvent(new CustomEvent("dvrRadioPlayerToPlayBack"));
    }

    private handleTogglePlayPause = (onTogglePlayPause: () => TogglePlayAction) => {
        const { radioScheduleList, liveBroadCastDVRSource } = this.props
        const { showPlayingIndex, isAdPlaying, playbackProgramIndex } = this.state

        return () => {
            const action = onTogglePlayPause();
            const currentProgram = radioScheduleList[showPlayingIndex]
            if (!isAdPlaying && action == TogglePlayAction.PAUSE && liveBroadCastDVRSource && playbackProgramIndex == -1 && currentProgram.isProgramLive) {
                const liveCurrentPlayTime = (new Date().getTime() - new Date(currentProgram.startProgram).getTime()) / 1000
                setTimeout(() => this.toPlayback(liveCurrentPlayTime, true), 0)
            }
            return action;
        }
    }

    private handlePlayerProgramEnd = () => {
        this.setState({ playbackProgramIndex: -1 })
    }

    private handleFloatingPlayerClose = (audioPlayButtonCloseHandler: () => void) => () => {
        this.setState({ playbackProgramIndex: -1 })
        setTimeout(audioPlayButtonCloseHandler, 0)
    }

    render() {
        const { isHlsLoaded, isHlsLoadingError } = this.state;
        if (!isHlsLoaded && !isHlsLoadingError) return null;

        const { showPlayingIndex, playbackProgramIndex, startPostion, isAdPlaying } = this.state
        const { title, radioScheduleList, isMobileWeb, liveBroadCastDVRSource, playerComponentaRenderer, shouldAppearOnComponentOutOfView, liveBroadCastSource, site, isSimplePlayer } = this.props
        const item = radioScheduleList[playbackProgramIndex < 0 ? showPlayingIndex : playbackProgramIndex] || emptyProgramItem;
        const browserDisplayMediaMetaData = { title: item.title, artist: site === "radionas" ? "radioNas" : "ynet", album: title }
        const source = playbackProgramIndex < 0 ? (isHlsLoadingError || !liveBroadCastDVRSource ? liveBroadCastSource : liveBroadCastDVRSource) : item.dvrSource;
        const programDurationSeconds = (new Date(item.endProgram).getTime() - new Date(item.startProgram).getTime()) / 1000;
        const adTagUrl = buildAdTag(isMobileWeb);

        return (
            <AudioPlayButton
                isLive="LIVE"
                ref={this.setPlayButtonRef}
                audioUrl={source}
                durationSeconds={0}
                shouldAppearOnComponentOutOfView={shouldAppearOnComponentOutOfView}
                browserDisplayMediaMetaData={browserDisplayMediaMetaData}
                startPostion={startPostion}
                onEnd={this.handlePlayerProgramEnd}
                adTag={adTagUrl}
                isAdPlaying={isAdPlaying}
                onAdPlayingStateChange={this.onAdPlayingStateChange}
                renderer={(rendererProps: AudioRendererProps) =>
                    playerComponentaRenderer({
                        programItem: item,
                        isAudioPlaying: rendererProps.isAudioPlaying,
                        onTogglePlayPause: this.handleTogglePlayPause(rendererProps.onTogglePlayPause),
                        onRefChanged: rendererProps.onRefChanged
                    })
                }
                floatingPlayerRenderer={(rendererProps) => {
                    return isSimplePlayer ?
                        <SimpleLiveFloatingPlayer
                            site={site}
                            programItem={item}
                            isMobileWeb={isMobileWeb}
                            isAudioPlaying={rendererProps.isAudioPlaying}
                            isFloatingPlayerVisible={rendererProps.isFloatingPlayerVisible}
                            audioElement={rendererProps.audioElement}
                            isSeeking={rendererProps.isSeeking}
                            onClose={this.handleFloatingPlayerClose(rendererProps.onClose)}
                            onTogglePlayPause={this.handleTogglePlayPause(rendererProps.onTogglePlayPause)}
                        />
                        :
                        <LiveFloatingRadioPlayer
                            programItem={item}
                            isMobileWeb={isMobileWeb}
                            isAudioPlaying={rendererProps.isAudioPlaying}
                            isFloatingPlayerVisible={rendererProps.isFloatingPlayerVisible}
                            audioElement={rendererProps.audioElement}
                            onClose={this.handleFloatingPlayerClose(rendererProps.onClose)}
                            isMuted={rendererProps.isMuted}
                            isSeeking={rendererProps.isSeeking}
                            handleMuteSwitch={rendererProps.handleMuteSwitch}
                            toPlayback={this.toPlayback}
                            onTogglePlayPause={this.handleTogglePlayPause(rendererProps.onTogglePlayPause)}
                        >
                            {isAdPlaying ?
                                <AdsProgressBar
                                    adDuration={rendererProps.adDuration}
                                    adPlaybackTime={rendererProps.adPlaybackTime}
                                    onSeeked={rendererProps.onseeked}
                                />
                                :
                                playbackProgramIndex < 0 ?
                                    <LiveProgressBar
                                        playbackTime={rendererProps.playbackTime}
                                        onSeeked={rendererProps.onseeked}
                                        toPlayback={this.toPlayback}
                                        startProgram={item.startProgram}
                                        isDisabled={!source.endsWith(".m3u8")}
                                        isLive={item.isProgramLive}
                                    />
                                    :
                                    <PlaybackProgressBar
                                        durationSeconds={programDurationSeconds}
                                        playbackTime={rendererProps.playbackTime}
                                        onSeeked={rendererProps.onseeked}
                                        toLive={this.toLive}
                                        isDisabled={!source.endsWith(".m3u8")}
                                    />
                            }
                        </LiveFloatingRadioPlayer>
                }
                }
            />
        )
    }
}

const emptyProgramItem: RadioProgramItem = {
    title: "",
    startTime: "",
    endTime: "",
    isProgramLive: false,
    hideHours: false,
}