import { analyticsPushFunc } from "../../../widgetsUtils"

interface MailShareButtonProps {
    className?: string
    handleOnClick?: () => void
    url?: string
    isEnglish?: boolean
    lang?: string
    textToShare?: string 
}

export class MailShareButton extends React.Component<MailShareButtonProps, {}>{
    static defaultProps = { url: "", lang: "he" }
    private mailIcon = "/images/icons/send_icon.png"
    private current_url = window.location.href


    static openMailShare = (body: string, url: string, lang: string, analyticsCb?: () => void, content: string = "", current_article_id?: string, hashPart?: string) => {
        const convertedBody = replaceCharacters(body)
        let title;
        let utm_term = current_article_id ? current_article_id : window.articleId;
        let urlTerm = utm_term ? `&utm_term=${utm_term}` : '';
        if (lang === "he") title = "כתבה מעניינת מ- Ynet"
        else if (lang === "heFlash") title = "מבזק מעניין מ- ynet"
        else if (lang === "heMynet") title = "כתבה מעניינת מ- Mynet"
        else if (lang === "heBlog") title = "פוסט מעניין שקראתי בבלוג"
        else if (lang === "heCalcalist") title = "כתבה מעניינת מכלכליסט"
        else if (lang === "en") title = "Interesting article from- Ynetnews"
        else if (lang === "enCtech") title = "Interesting article from- Ctech"
        else if (lang === "ru") title = "Интересная статья на сайте- Vesti"
        else if (lang === "es") title = "Interesante artículo de Ynet Español"
        const hostname = encodeURIComponent(window.location.hostname.replace("www.", ""));
        const utmContent = content.trim() ? `&utm_content=${encodeURIComponent(content)}` : "";
        url = `${url}?utm_source=${hostname}&utm_medium=Share&utm_campaign=mail${urlTerm}${utmContent}`;
        url = hashPart ? url + "#" + hashPart : url;
        window.location.href = `mailto:?subject=${title}&body=${encodeURIComponent(convertedBody)} %0d %0d ${encodeURIComponent(url)}`;
        analyticsCb && typeof analyticsCb === "function" && analyticsCb()
    }

    private getCurrentUrl = () => {
        const { url } = this.props
        let urlForPopUp: string;
        if (url !== "") {
            urlForPopUp = url
        }
        else { urlForPopUp = this.current_url }
        return urlForPopUp;
    }

    private handleShareBtnClicked = () => {
        const { handleOnClick, lang, textToShare } = this.props
        MailShareButton.openMailShare(document.title, this.getCurrentUrl(), lang, () => analyticsPushFunc("Mail", "Photo Gallery", textToShare), "Photo Gallery")
        if (handleOnClick) { handleOnClick() }
    }


    render() {
        const { className, isEnglish, lang } = this.props
        let text;
        if (lang === "he") text = "שתפו בדואל"
        else if (lang === "en" || lang === "enCtech") text = "Share by Mail"
        else if (lang === "ru") text = "послать другу"
        else if (lang === "es") text = "Enviar a un amigo"
        return <button className="mlBtn" title={text} aria-label={text} onClick={e => this.handleShareBtnClicked()} >
            <img src={this.mailIcon} aria-hidden={true} alt={text} className={className} />
        </button>
    }
}
const replaceCharacters = (str: string) => {
    const obj = { '&quot;': `"`, '&lt;': `<`, '&gt;': `>`, '&amp;': `&`, '<\/?span[^>]*>': ``, '&#39;': `'` }
    for (var x in obj) {
        str = str.replace(new RegExp(x, 'g'), obj[x]);
    }
    return str;
}