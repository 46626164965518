import { VideoSettingsData, LinkedItemType, ComponentaIconType, IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { SiteItemData, SiteMediaData, SiteImageData } from "widgets/interfaces"
import { SiteMedia } from "../../../../siteWidgets/siteMedia";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay"
import { MediaContentTypes } from "constants/contentTypes"
import { MarketingSign } from "../../../../commonComponents/inline/marketingSign/marketingSign"


interface MultiImagesFrontProps {
    imageWidth: number
    imageHeight: number
    item?: SiteItemData
    videoSettings?: VideoSettingsData
    fieldsDisplaySettings?: IFieldDisplayData
    margin?: number
    onRowDisplay: boolean
    titleColor: string
    isSmallItem?: boolean
    isImageOnly: boolean
}



export class MultiImagesItemFront extends React.Component<MultiImagesFrontProps>{

    private getIcon = (icon: ComponentaIconType, hasVideo: boolean) => {
        if (icon === "automatic" && hasVideo) return "video"
        return icon;
    }

    private getSlotViewStyle = () => {
        const { onRowDisplay, margin, isImageOnly } = this.props;
        let marginStyle = { margin: "0 10px 24px" };
        if (onRowDisplay) {
            marginStyle = { margin: "unset", marginRight: margin }
        }
        if (isImageOnly) {
            marginStyle = { margin: "0 10px 10px" }
        }
        return marginStyle;
    }

    public render() {
        const { imageWidth, item, imageHeight, videoSettings, fieldsDisplaySettings, titleColor, isSmallItem, isImageOnly } = this.props;
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        const slotViewStyle = this.getSlotViewStyle();
        const titleStyle = { color: titleColor };
        const icon = item.icon ? item.icon : "automatic"
        const showUpInfoArea = fieldsDisplaySettings.isChannelNameVisible
        const shouldDisplaySeparator = fieldsDisplaySettings.isAuthorVisible && item.itemType === LinkedItemType.ARTICLE && fieldsDisplaySettings.isDateVisible && item.author.length > 0;
        const shouldDisplayDate = fieldsDisplaySettings.isDateVisible && item.itemType === LinkedItemType.ARTICLE;
        return (<div className="slotView" style={slotViewStyle}>

            <div className="imageArea" data-tb-thumbnail=''>
                <div className="MediaCarousel" style={{ width: imageWidth, height: imageHeight }} >
                    <SiteMedia
                        data={item.media !== undefined ? item.media : emptyMedia}
                        width={imageWidth}
                        height={imageHeight}
                        videoSettings={videoSettings}
                        itemLinkUrl={item.titleLink}
                        isImageLinkable={true}
                        linkTarget={item.titleTargetLink !== undefined && item.titleTargetLink}
                    />
                    <SlotIconDisplay itemId={item.itemId} icon={this.getIcon(icon, item.hasVideo)} iconClassName="medium" isIconVisible={item.hasVideo || icon === "video" || icon === "automatic"} />
                </div>
                {item.isMarketingContent && <MarketingSign isSmallItem={isSmallItem} marketingContentText={item.marketingContentText} />}
                {!item.isMarketingContent && item.redLabelText && item.redLabelText.length > 0 && <span className={`basicLabel labelText ${item.textPlace}`}>{item.redLabelText}</span>}
            </div>
            {!isImageOnly && <div className="slotTextArea">
                {showUpInfoArea && <div className="upInfoArea">
                    {fieldsDisplaySettings.isChannelNameVisible && item.subchannelName !== null && item.subchannelName}
                </div>}
                {fieldsDisplaySettings.isTitleVisible && <div className={`slotTitle medium ${item.isCommertial ? "commertialTitle" : ""}`} >
                    <HyperLinkBuilder
                        href={item.titleLink}
                        isDataTbLink={true}
                        linkTarget={item.titleTargetLink !== undefined && item.titleTargetLink}
                        children={item.title} >
                        <div dangerouslySetInnerHTML={{ __html: item.title }} style={titleStyle} data-tb-title='' />
                    </HyperLinkBuilder>
                </div>}
                {fieldsDisplaySettings.isSubTitleVisible && <div className="slotSubtitle" >
                    <HyperLinkBuilder
                        href={item.titleLink}
                        linkTarget={item.titleTargetLink !== undefined && item.titleTargetLink}
                        children={item.title} >
                        <div dangerouslySetInnerHTML={{ __html: item.subTitle}} style={titleStyle} data-tb-title='' />
                    </HyperLinkBuilder>
                </div>}

                <div className="moreDetails">
                    {fieldsDisplaySettings.isAuthorVisible && <span className="authorField" data-tb-author=''>{item.author}</span>}
                    {shouldDisplaySeparator && <span>|</span>}
                    {shouldDisplayDate && <DateDisplay date={item.dateUpdatedOnSite} isTbDate />}
                </div>
            </div>}
        </div>
        )
    }
}